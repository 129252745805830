import * as React from "react"
import { Container, Row, Col, ListGroup, Card } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import {
  GiFlexibleStar,
  GiEasel,
  GiAbstract030,
  GiMeatHook,
} from "react-icons/gi"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FaArrowAltCircleDown } from "react-icons/fa"
// import SVGComponent from "../images/svg/TSC_Web1.svg"

const WebDesign = () => {
  return (
    <Layout>
      <Seo
        title="Experts in Custom Web Design and Digital Building!"
        description="We offer custom and super responsive websites from Startups to big enterprise. We help you build a website which differentiates you from the crowd."
        keyword="web design, website design"
      />
      <Container fluid className="min-vh-100 position-relative">
        <br />
        <br />
        <br />
        <Row>
          <Col
            md={6}
            sm={12}
            className="text-center my-auto"
          >
            <h1 className="text3d fs-3 mt-4 mb-5">WEBSITE DESIGN</h1>
          </Col>
          <Col md={6} sm={12}>
            <StaticImage
              alt="Web Design and development poster"
              src={"../images/web_design.png"}
              placeholder={"tracedSVG"}
              layout={"constrained"}
              formats={["auto", "webp", "avif"]}
            />
          </Col>
        </Row>
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <div
            className="bg-text"
            data-bg-text="WEB DESIGN"
            data-aos="zoom-in-right"
          >
            <br />
            <br />
            Your website is your face in the online marketplace. In order for
            you to keep your business on the up and make sure that you are able
            to retain your customers, you need a website that is not just
            appealing to the eyes but that which is equally engaging. And with
            more and more consumers drifting towards smart phones, you need a
            website that is compatible through multiple platforms and multiple
            devices. You need a website that has a flexible content that will
            communicate with your customers and keep them glued onto your page.
            And that is exactly what we at TSC(TheSoftCode) strive to provide
            for you.
            <br />
            <br />
            When it comes to making a website, it has two important facets – the
            design and the development. People often confuse one with the other.
            However, you need to remember that as much as the design is
            appealing, it will not engage your customers if the design is not
            responsive and engaging. At Softcode, we develop codes that
            complement your design and provide an ergonomic interface that is
            free of friction.
          </div>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WHAT WE DO
        </h2>
        <br />
        <Container className="text-white text-center fs-5">
          We have a professional team of expert designers and programmers who
          will work to deliver a website that will live up to your expectations
          and then some. As part of our web development services, we provide you
          with,
          <br />
          <br />
          <ListGroup className="text-start">
            <ListGroup.Item>
              • Websites that meets your business goals, expectations and are
              completely tested for its ability.
            </ListGroup.Item>
            <ListGroup.Item>
              • Smart technology solutions and scalable architecture to enhance
              the productivity of your website on any platform.
            </ListGroup.Item>
            <ListGroup.Item>
              • Customized and quality websites to ensure that you stand out in
              the crowd.
            </ListGroup.Item>
            <ListGroup.Item>
              • Transparency in the process at every stage of design and
              development.
            </ListGroup.Item>
            <ListGroup.Item>
              • Assurance on quality through multiple qualitative tests
              throughout the development process.
            </ListGroup.Item>
            <ListGroup.Item>
              • Client level user testing to give you hands on experience prior
              to launch.
            </ListGroup.Item>
            <ListGroup.Item>
              • Efficient deployment and continuous support, monitor and
              maintenance.
            </ListGroup.Item>
          </ListGroup>
          <br />
          <br />
          In simple words, we give you a website that will not just automate
          your processes and business but that which will help you to grow as
          well. We offer a variety of websites in multiple categories including
          eCommerce, Corporate, Brand, Mobile, HTML5, etc. We use a wide range
          of technologies like XHTML, .Net, Ajax, CSS, XML and more in our
          development. Apart from website development we also have talented and
          experienced content writers who can effectively manage your web
          content and ensure that it is always trending with the latest buzz
          words. We have specialized writers who understand the concept behind
          SEO and ensure that your content is SEO rich.
        </Container>
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white text-center">
        <br />
        <br />
        <Container className="align-items-center fs-5">
          <h2
            className="text-center bg-textdark"
            data-aos="flip-up"
          >
            WHO WE ARE
          </h2>
          <br />
          Every business has its unique requirements and expectations. At
          Softcode, we give you a team of skilled technicians who have decade
          worth of experience and knowledge in multiple industries and who are
          well versed in the developing trends in the technology. We help
          provide you a website that does not just exist but makes your business
          visible and credible. At Softcode, we have a team of web development
          programmers and coders who have exposure to the different programming
          languages including Objective C, Java, etc. and who are highly skilled
          in using multiple GUI (Graphical User Interface) and other interface
          frameworks. We use our knowledge and our experience to give you a
          solution that is tailored to meet your individual business segments.
        </Container>
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WEBSITE FOR EVERY BUDGET
        </h2>
        <br />
        <Container className="text-white text-center fs-5">
          At Softcode we do not compromise on the quality of the websites we
          develop and in the technologies that we employ. We develop websites
          that fits with the increasing demand and the evolving technology while
          being fully functional and highly adaptable. And we do this at a cost
          that will fit into any budget. Our pricing is always our strength,
          next only to our quality.
          <br />
          <br /> Whether you are looking to build a new website or you are
          looking to rejuvenate an existing one or you are simply looking to
          move your backend data system to a new framework, we have solution for
          every phase and we have it in every budget. Our web development
          services will be friendly to your pocket while the websites we create
          will be friendly to the users.
          <br />
          <br />
          Contact us to build a robust, user friendly and responsive website for
          your company.
        </Container>
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <Container className="align-items-center text-center fs-5">
          <br />
          <br />
          <h2
            className="text-center bg-textdark"
            data-aos="flip-up"
          >
            WHY CHOOSE US
          </h2>
          <br />
          At Softcode, we first listen to your requirements and then provide you
          solutions that are complete and holistic in nature. We offer solutions
          that are
          <br />
          <br />
          <Card>
            <Card.Header>
              <GiFlexibleStar />
              &nbsp;Flexible
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Our methodology involves technologies that are highly flexible
                and can provide you a faster and a better response time to your
                customers.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <GiEasel />
              &nbsp; Easy to use
            </Card.Header>
            <Card.Body>
              <Card.Text>
                With our proficiency in multiple UI and UX interfaces and design
                frameworks, we offer you a solution that will be easily scalable
                across multiple platforms, including mobile devices.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <GiAbstract030 />
              &nbsp; Adaptable
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Our applications and websites are also easily flexible for
                further upgrades in the future making them adaptable to any
                changes.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <GiMeatHook />
              &nbsp; Quality assured
            </Card.Header>
            <Card.Body>
              <Card.Text>
                We have a separate phase in our development process where we
                carry out intense testing to ensure that our solutions are of
                the best quality. We also offer you the prototype for your
                testing and take your feedback to ensure that the websites we
                provide are of best quality.
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          At Softcode, we work hard to provide you and your customers an
          unparalleled experience. With our assistance, you can be rest assured
          that your websites offer superior performance and your business sees
          an upward trend in profitability. Contact us anytime to get an
          appointment for a consultation.
          <br />
          <br />
        </Container>
      </Container>
    </Layout>
  )
}

export default WebDesign
